'use strict';

//This file contains the ES6 extensions to the core Promises/A+ API

var Promise = require('./core.js');

module.exports = Promise;

/* Static Functions */

var TRUE = valuePromise(true);
var FALSE = valuePromise(false);
var NULL = valuePromise(null);
var UNDEFINED = valuePromise(undefined);
var ZERO = valuePromise(0);
var EMPTYSTRING = valuePromise('');

function valuePromise(value) {
 var p = new Promise(Promise._99);
 p._37 = 1;
 p._12 = value;
 return p;
}
Promise.resolve = function (value) {
 if (value instanceof Promise) return value;

 if (value === null) return NULL;
 if (value === undefined) return UNDEFINED;
 if (value === true) return TRUE;
 if (value === false) return FALSE;
 if (value === 0) return ZERO;
 if (value === '') return EMPTYSTRING;

 if (typeof value === 'object' || typeof value === 'function') {
 try {
 var then = value.then;
 if (typeof then === 'function') {
 return new Promise(then.bind(value));
 }
 } catch (ex) {
 return new Promise(function (resolve, reject) {
 reject(ex);
 });
 }
 }
 return valuePromise(value);
};

Promise.all = function (arr) {
 var args = Array.prototype.slice.call(arr);

 return new Promise(function (resolve, reject) {
 if (args.length === 0) return resolve([]);
 var remaining = args.length;
 function res(i, val) {
 if (val && (typeof val === 'object' || typeof val === 'function')) {
 if (val instanceof Promise && val.then === Promise.prototype.then) {
 while (val._37 === 3) {
 val = val._12;
 }
 if (val._37 === 1) return res(i, val._12);
 if (val._37 === 2) reject(val._12);
 val.then(function (val) {
 res(i, val);
 }, reject);
 return;
 } else {
 var then = val.then;
 if (typeof then === 'function') {
 var p = new Promise(then.bind(val));
 p.then(function (val) {
 res(i, val);
 }, reject);
 return;
 }
 }
 }
 args[i] = val;
 if (--remaining === 0) {
 resolve(args);
 }
 }
 for (var i = 0; i < args.length; i++) {
 res(i, args[i]);
 }
 });
};

Promise.reject = function (value) {
 return new Promise(function (resolve, reject) {
 reject(value);
 });
};

Promise.race = function (values) {
 return new Promise(function (resolve, reject) {
 values.forEach(function(value){
 Promise.resolve(value).then(resolve, reject);
 });
 });
};

/* Prototype Methods */

Promise.prototype['catch'] = function (onRejected) {
 return this.then(null, onRejected);
};
